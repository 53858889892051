import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Terminal from '../components/Terminal/Terminal.vue'
import TrendList from '../components/TrendList/TrendList.vue'
import DensityList from '../components/DensityList/DensityList.vue'
import Trend from '../components/Trend/Trend.vue'
import OrderBook from '../components/OrderBook/OrderBook.vue'
import CandlesTradingView from '../components/Candles/TradingView/LwWrap.vue'
import CandlesTradingVue from '../components/Candles/TradingVue/TradingVue.vue'
import Clusters from '../components/Clusters/Clusters.vue'
import CreateOrder from '../components/CreateOrder/CreateOrder.vue'
import Scalper from '../components/Scalper/Scalper.vue'
import Exchanges from '../components/Exchanges/Exchanges.vue'
import Pairs from '../components/Pairs/Pairs.vue'
import Instruments from '../components/Instruments/Instruments.vue'
import Trades from '../components/Trades/Trades.vue'
import Widget from '../components/Widget/Widget.vue'
import WidgetInWrapper from '../components/Widget/WidgetInWrapper.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // {
    //   path: '/',
    //   name: 'main',
    //   component: TrendList,
    //   meta: { title: 'MarketMaker' }
    // },
    // {
    //   path: '/trend',
    //   name: 'trend',
    //   component: TrendList,
    //   meta: { title: 'MarketMaker' }
    // },
    // {
    //   path: '/trend/:from/:to',
    //   name: 'trend',
    //   component: Trend,
    //   meta: { title: 'MarketMaker' }
    // },
    {
      path: '/',
      name: 'density-list',
      component: DensityList,
      meta: { title: 'MarketMaker' }
    },
    // {
    //   path: '/terminal',
    //   name: 'terminal',
    //   component: Terminal,
    //   meta: { title: 'MarketMaker - scalper screener' }
    // },
    // {
    //   path: '/sitemap',
    //   name: 'sitemap',
    //   component: HomeView
    // },
    {
      path: '/scalper',
      name: 'scalper',
      component: Scalper
    },
    {
      path: '/orderbook',
      name: 'orderbook',
      component: OrderBook
    },
    {
      path: '/candles-trading-view',
      name: 'candles-trading-view',
      component: CandlesTradingView,
    },
    {
      path: '/candles-trading-vue',
      name: 'candles-trading-vue',
      component: CandlesTradingVue,
    },
    {
      path: '/clusters',
      name: 'clusters',
      component: Clusters,
    },
    {
      path: '/create-order',
      name: 'create-order',
      component: CreateOrder,
    },
    {
      path: '/exchanges',
      name: 'exchanges',
      component: Exchanges,
    },
    {
      path: '/pairs',
      name: 'pairs',
      component: Pairs,
    },
    {
      path: '/instruments',
      name: 'instruments',
      component: Instruments,
    },
    {
      path: '/trades',
      name: 'trades',
      component: Trades,
    },
    {
      path: '/widget',
      name: 'widget',
      component: WidgetInWrapper,
    },
  ]
})

// router.beforeEach((to, from) => {
//   (document as any).title = to.meta?.title ?? 'Default Title'
// })

export default router
