<template>
  <div class="clusters" ref="clustersRef" :style="`margin-top: ${commonMargin}px`">
    <!-- <div class="temp">{{temp}}</div> -->
    <n-space vertical v-for="candle in dataComputed" :key="candle.timestamp" class="cluster-candle" :style="`margin-top: ${candle.margin}px`">
      <n-table :bordered="false" :single-line="false" size="small">
        <tbody>
          <tr v-for="(item, index) in candle.clusterPoints" :key="`${index}-asks`">
            <td>
              <div class="gradient-background" :style="getGradientStyle(item)">
                {{ item.price && item.price.toFixed(2) || item.volume }}
              </div>
            </td>
          </tr>
        </tbody>
      </n-table>
    </n-space>
    <div class="center" :style="{ top: calculatePositionOfCenter + 'px' }"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, computed, onMounted, onUnmounted } from 'vue'
import { defineProps } from 'vue'
import _ from 'lodash';
import { useMainStore } from '@/stores/main';
import type { Candle, ClusterPoint } from '@/types';

interface ExtendedClusterPoint extends ClusterPoint {
  priceUp?: number;
  priceDown?: number;
}

interface CandleData {
  clusterPoints: ClusterPoint[];
  timestamp: number;
  margin?: number;
  pointPrice?: number;
  priceDiff?: number;
  stepsDiff?: number;
  rowHeight?: number;
  widgetHeight?: number;
  centerHeight?: number;
}

interface Props {
  baseId?: string;
  quoteId?: string;
  exchangeId?: string;
  data?: CandleData[];
}

const props = defineProps<Props>();

const { exchangeId, baseId, quoteId } = toRefs(props);

const commonMargin = computed(() => {
  if (!baseId?.value || !quoteId?.value || !exchangeId?.value) return 0;
  const id = `${baseId.value}/${quoteId.value}/${exchangeId.value}`;
  const clustersCenter = store?.scalpers?.[id]?.clustersCenter || 0;
  const orderBooksCenter = store?.scalpers?.[id]?.orderBooksCenter || 0;
  if (clustersCenter === 0 || orderBooksCenter === 0) return 0;
  return Math.max(orderBooksCenter, clustersCenter) - clustersCenter;
});

const store = useMainStore();
const clustersRef = ref<HTMLElement | null>(null);
const temp = ref<any>();

const calculateMargin = (candle: CandleData, isCenter: boolean = false): CandleData => {
  if (!clustersRef.value?.parentElement) return candle;
  const widgetHeight = clustersRef.value.parentElement.offsetHeight;
  const centerHeight = isCenter ? widgetHeight/2 : 0;
  const pointPrice = candle?.clusterPoints?.[0]?.price || 0;
  const id = `${props.baseId}/${props.quoteId}/${props.exchangeId}`;
  const spreadPrice = store?.scalpers?.[id]?.spreadPrice || 0;
  const priceDiff = pointPrice - spreadPrice;
  const stepsDiff = priceDiff / store.priceStep;
  const rowHeight = store.rowHeight;
  const margin = (-stepsDiff * rowHeight) + centerHeight - rowHeight;

  return {
    ...candle,
    margin,
    pointPrice,
    priceDiff,
    stepsDiff,
    rowHeight,
    widgetHeight,
    centerHeight,
  };
};

const dataComputed = computed(() => {
  const data = _.cloneDeep(props.data) || [];
  for (let candle of data) {
    const clusterPoints = candle.clusterPoints;
    const groupedClusterPoints: ExtendedClusterPoint[] = [];
    let currentGroup: ExtendedClusterPoint | null = null;

    for (const point of clusterPoints) {
      const priceGroup = Math.ceil(point.price! / store.priceStep) * store.priceStep;

      if (currentGroup && currentGroup.price !== priceGroup) {
        groupedClusterPoints.push(currentGroup);
        currentGroup = null;
      }

      if (!currentGroup) {
        currentGroup = {
          price: priceGroup,
          priceUp: priceGroup,
          priceDown: priceGroup + store.priceStep,
          volume: point.volume || 0,
          percent: point.percent || 0,
          empty: false,
        };
      } else {
        currentGroup.volume = (currentGroup.volume || 0) + (point.volume || 0);
        currentGroup.percent = (currentGroup.percent || 0) + (point.percent || 0);
      }
    }

    if (currentGroup) {
      groupedClusterPoints.push(currentGroup);
    }

    candle.clusterPoints = groupedClusterPoints;
    const _candle = calculateMargin(candle, false);
    candle.margin = _candle.margin;
    candle.pointPrice = _candle.pointPrice;
    candle.priceDiff = _candle.priceDiff;
    candle.stepsDiff = _candle.stepsDiff;
    candle.rowHeight = _candle.rowHeight;
    candle.widgetHeight = _candle.widgetHeight;
    candle.centerHeight = _candle.centerHeight;
  }
  return data;
});

const getGradientStyle = (item: ExtendedClusterPoint) => {
  const percent = item.percent || 0;
  const empty = item.empty || false;
  const isCenter = false;
  let leftPercent;
  let rightPercent;
  if (isCenter) {
    leftPercent = (100-percent)/2;
    rightPercent = 100-leftPercent;
  } else {
    leftPercent = 0;
    rightPercent = percent;
  }

  const color = 'rgb(38 57 53)';
  return {
    background: `
      linear-gradient(
        90deg, rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) ${leftPercent}%,
        ${color} ${leftPercent}%,
        ${color} ${rightPercent}%,
        rgba(255, 255, 255, 0) ${rightPercent}%,
        rgba(255, 255, 255, 0) 100%
    )`,
    color: empty ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.82)',
  };
};

const calculatePositionOfCenter = computed(() => {
  if (!dataComputed.value.length) { return 0 };
  if (!store.scalpers) { return 0 };
  if (!baseId?.value || !quoteId?.value || !exchangeId?.value) { return 0 };
  const id = `${baseId.value}/${quoteId.value}/${exchangeId.value}`;
  const rowHeight = store?.rowHeight;
  const priceStep = store?.priceStep;
  const candle = _.cloneDeep(dataComputed?.value);
  const widgetHeight = clustersRef?.value?.parentElement?.offsetHeight || 0;
  const isCenter = false;
  const centerHeight = isCenter ? widgetHeight/2 : 0;

  const maxPricesInClusterPoints = candle.map((candle) => candle.clusterPoints?.[0].price);
  const maxPrice = Math.max(...(maxPricesInClusterPoints.filter(p => p !== undefined) as number[]));
  const spreadPrice = store?.scalpers?.[id]?.spreadPrice || 0;
  const diffPrice = maxPrice - spreadPrice;
  const steps = diffPrice / priceStep;
  const marginTop = steps * rowHeight;
  temp.value = JSON.stringify({priceStep, spreadPrice, maxPrice, diffPrice, steps, marginTop}, null, 2)
  if (store.scalpers?.[id]) store.scalpers[id].clustersCenter = marginTop;
  return marginTop + centerHeight;
})
</script>

<style scoped lang="sass">
.clusters
  display: flex
  position: relative
  height: 100%
  overflow-y: auto
.cluster-candle
  left: 10px
.gradient-background
  white-space: nowrap
  width: 200px
  min-width: 200px
  max-width: 200px
.temp
  position: absolute
  bottom: 30px
  left: 30px
  width: 300px
.center
  top: 100px
  position: absolute
  background: pink
  height: 2px
  width: 100%
</style>
