<template>
  <div>
    <!-- <div class="temp-top">
    clustersCenter: {{store?.scalpers?.[`BTC/USDT/binance`]?.clustersCenter || "-"}}
    orderBooksCenter: {{store?.scalpers?.[`BTC/USDT/binance`]?.orderBooksCenter || "-"}}
    </div> -->
    <!-- {{ store.sliderValue }} -->
    <!-- {{ data }} -->
    <!-- {{store.historyEnabled}} -->
    <ClustersUi :data="data" :baseId="baseId" :quoteId="quoteId" :exchangeId="exchangeId" />
  </div>
</template>

<script setup lang="ts">
import ClustersUi from './ClustersUi.vue';
import demoData from './data'
import { ref, onMounted, toRefs, reactive, watch, computed } from 'vue';
import { useMainStore } from '@/stores/main';
import { time } from 'console';
import _ from 'lodash';

const store = useMainStore();
const data = ref(store.demo ? demoData : []) // demoData, []

const props = defineProps({
  widgetRef: Object,
  exchangeId: String,
  baseId: String,
  quoteId: String,
  timeframe: String,
  lastN: Number,
})
const { exchangeId, baseId, quoteId, timeframe, lastN } = toRefs(props);

const fetchData = async () => {
  data.value = (await store.fetchCandlesRest({exchangeId: exchangeId?.value, baseId: baseId?.value, quoteId: quoteId?.value, timeframe: timeframe?.value, type: 'clusters', lastN: store.clustersN || lastN?.value}))
}

onMounted(async () => {
  if (store.demo) return;
  fetchData()
  setInterval(async () => {
    await fetchData()
  }, 1000)
})

watch(() => [store.sliderValue, store.historyEnabled], async ([newSliderValue, newHistoryEnabled], [oldSliderValue, oldHistoryEnabled]) => {
  if (newHistoryEnabled && newSliderValue !== oldSliderValue) {
    await fetchData()
  }
})
</script>


<style scoped lang="sass">
.temp-top
  position: absolute
  top: 10px
  left: 10px
</style>
