<template>
  <div>
    <div class="total">total: {{filteredInstruments.length}}</div>
    <div class="search">
      <n-input
        v-model:value="filterText"
        type="textarea"
        :rows="isTextareaExpanded ? 5 : 1"
        placeholder="Enter filter rules (e.g. 'ALL min 1k' or 'BTC/USDT MEXC:FUTURES min 40M')"
        @focus="isTextareaExpanded = true"
        @blur="handleBlur"
        @keydown="handleKeyDown"
      />
      <n-button 
        v-if="isTextareaExpanded"
        class="save-button" 
        @click="saveFilters" 
        type="primary" 
        size="small"
      >
        Save Filters
      </n-button>
    </div>
    <n-table :bordered="false" :single-line="false" size="small">
        <tbody>
          <tr v-for="(item, index) in filteredInstruments" :key="`${index}-pairs`">
            <td>
              
              <div class="pair">
                <div class="left">
                  <div class="name">
                    {{ item.baseId }}/{{ item.quoteId }}
                  </div>
                  <n-tag class="active" type="warning">
                    {{ item.exchange.toUpperCase() }}:{{ item.marketType.toUpperCase() }}
                  </n-tag>
                </div>
                <div class="right">
                  <!-- <n-input type="text" placeholder="min USDT" /> -->
                  <!-- <n-input type="text" placeholder="%" /> -->
                  <n-tag class="active" type="white">
                    Updated {{ getTimeDifference(item.lastUpdate) }} ago
                  </n-tag>
                  <n-tag class="active" type="white">
                    max bid: {{ formatNumber(item.metrics.maxBidDensity.volumeUSDT) }} usdt
                    <br />
                    max ask: {{ formatNumber(item.metrics.maxAskDensity.volumeUSDT) }} usdt
                  </n-tag>

                  <!-- TODO: add list of exchanges -->
                  <a :href="item.tradingUrl">
                    <n-button tertiary circle type="info">
                      <template #icon>
                        <n-icon ><ExternalLink /></n-icon>
                      </template>
                    </n-button>
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </n-table>
    <!-- <DensityListUi :data="data" :search="search" :setSearch="setSearch"/> -->
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { demoData } from './data'
import _ from 'lodash'
import axios from 'axios'
import { ExternalLink, ArrowBarToUp, ArrowBarToDown } from '@vicons/tabler'

interface InstrumentData {
  baseId: string;
  quoteId: string;
  exchange: string;
  marketType: string;
  lastUpdate: number;
  metrics: {
    maxBidDensity: {
      volumeUSDT: number;
    };
    maxAskDensity: {
      volumeUSDT: number;
    };
  };
  tradingUrl: string;
}

const getTimeDifference = (lastUpdate: number) => {
  const currentTime = new Date().getTime();
  const diffInSeconds = Math.floor((currentTime - lastUpdate) / 1000);
  
  if (diffInSeconds < 60) return `${diffInSeconds}s`;
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
  return `${Math.floor(diffInSeconds / 86400)}d`;
}

function formatNumber(num: number) {
  if (Math.abs(num) >= 100000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (Math.abs(num) >= 100) {
    return (num / 1000).toFixed(1) + 'k';
  } else {
    return 0;
  }
}

const demoDataList = Object.values(demoData).sort((a: any, b: any) => {
  if (a.active && !b.active) {
    return -1
  }
  if (!a.active && b.active) {
    return 1
  }
  return 0
})

const search = ref("")
const filterText = ref("ALL min 1M\nLTC/USDT MEXC:FUTURES min 1.0k")
const isTextareaExpanded = ref(false)
const savedFilters = ref<{[key: string]: {minAmount: number}}>(
  JSON.parse(localStorage.getItem('densityFilters') || '{}')
)

const handleBlur = () => {
  // Don't collapse immediately to allow clicking the save button
  setTimeout(() => {
    isTextareaExpanded.value = false
  }, 200)
}

const parseAmount = (amount: string): number => {
  // Remove any whitespace and convert to lowercase for consistent handling
  const cleanAmount = amount.trim().toLowerCase()
  
  // If the amount is "0", return 0
  if (cleanAmount === "0") return 0
  
  // Extract the numeric part and the suffix
  const match = cleanAmount.match(/^(\d+\.?\d*)([kmb])?$/)
  if (!match) return 0
  
  const [, numStr, suffix] = match
  const num = parseFloat(numStr)
  
  const multiplier = suffix === 'k' ? 1000 
    : suffix === 'm' ? 1000000
    : suffix === 'b' ? 1000000000 
    : 1
  
  return num * multiplier
}

const saveFilters = () => {
  const filters: {[key: string]: {minAmount: number}} = {}
  const lines = filterText.value.split('\n').filter(line => line.trim())
  
  // First process ALL rule
  const allRule = lines.find(line => line.trim().toLowerCase().startsWith('all'))
  if (allRule) {
    const [, , amount] = allRule.trim().split(/\s+/)
    filters['ALL'] = { minAmount: parseAmount(amount) }
  }
  
  // Then process specific rules
  lines.forEach(line => {
    const trimmedLine = line.trim()
    if (!trimmedLine.toLowerCase().startsWith('all')) {
      const parts = trimmedLine.split(/\s+/)
      if (parts.length >= 4) {
        const [pair, exchangeType, , amount] = parts
        if (pair && exchangeType && amount) {
          // Format key exactly as in the data
          const [exchange, type] = exchangeType.split(':')
          const key = `${pair}_${exchange}:${type}`.toUpperCase()
          filters[key] = { minAmount: parseAmount(amount) }
        }
      }
    }
  })
  
  localStorage.setItem('densityFilters', JSON.stringify(filters))
  savedFilters.value = filters
}

const handleKeyDown = (e: KeyboardEvent) => {
  // Check for Ctrl+S or Cmd+S
  if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 's') {
    // Prevent the browser's save dialog
    e.preventDefault()
    
    // Only save if textarea is focused
    if (isTextareaExpanded.value) {
      saveFilters()
    }
  }
}

const getData = async () => {
  try {
    const response = await axios.get('https://metrics.marketmaker.cc/api/instruments', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    instrumentsData.value = response.data;
  } catch (error) {
    console.error(error)
  }
}

const instrumentsData = ref<InstrumentData[]>([])
const filteredInstruments = computed(() => {
  let filtered = instrumentsData.value
  
  if (savedFilters.value) {
    // Create a set of pairs that have specific rules (in uppercase)
    const specificRulePairs = new Set(
      Object.keys(savedFilters.value)
        .filter(key => key !== 'ALL')
        .map(key => key.toUpperCase())
    )

    filtered = filtered.filter(item => {
      const maxVolume = Math.max(
        item.metrics.maxBidDensity.volumeUSDT,
        item.metrics.maxAskDensity.volumeUSDT
      )
      
      // Create key in exactly the same format
      const pairKey = `${item.baseId}/${item.quoteId}_${item.exchange}:${item.marketType}`.toUpperCase()
      
      // Debug log to see what's happening
      console.log({
        pairKey,
        hasSpecificRule: specificRulePairs.has(pairKey),
        specificRules: Array.from(specificRulePairs),
        volume: maxVolume,
        specificFilter: savedFilters.value[pairKey],
        globalMin: savedFilters.value['ALL']?.minAmount
      })
      
      // If there's a specific rule for this pair, use it
      if (specificRulePairs.has(pairKey)) {
        const specificFilter = savedFilters.value[pairKey]
        return maxVolume >= specificFilter.minAmount
      }
      
      // If no specific rule, only then apply the ALL rule
      const globalMin = savedFilters.value['ALL']?.minAmount
      if (globalMin !== undefined) {
        return maxVolume >= globalMin
      }
      
      return true
    })
  }
  
  if (search.value) {
    filtered = filtered.filter(item => 
      `${item.baseId}/${item.quoteId}`.toLowerCase().includes(search.value.toLowerCase()) ||
      item.exchange.toLowerCase().includes(search.value.toLowerCase())
    )
  }
  
  return filtered
})

// Load saved filters on mount
onMounted(() => {
  getData()
  const saved = localStorage.getItem('densityFilters')
  if (saved) {
    savedFilters.value = JSON.parse(saved)
    // Reconstruct filter text from saved filters
    const lines = []
    if (savedFilters.value['ALL']) {
      lines.push(`ALL min ${formatNumber(savedFilters.value['ALL'].minAmount)}`)
    }
    Object.entries(savedFilters.value).forEach(([key, value]) => {
      if (key !== 'ALL') {
        // Split key back into pair and exchange type
        const [pair, exchangeType] = key.split('_')
        lines.push(`${pair} ${exchangeType} min ${formatNumber(value.minAmount)}`)
      }
    })
    filterText.value = lines.join('\n')
  }
  saveFilters()
})
</script>

<style scoped lang="sass">
.search
  margin-bottom: 16px
  display: flex
  gap: 8px
  align-items: flex-start

.save-button
  margin-top: 4px

.pair
  align-items: center
  display: flex
.left
  flex: 1 0 auto
  display: flex
  justify-content: left
  align-items: center
  .name
    margin-right: 8px

.right
  display: flex
  align-items: center
.active
  margin-right: 8px
.total
  position: fixed
  bottom: 8px
  right: 8px
</style>
