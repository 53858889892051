export const demoData = [
  {
    id: "BTC_USDT_BINANCE_SPOT",
    pair: "BTC_USDT",
    exchange: "binance",
    type: "spot",
  },
  {
    id: "ETH_USDT_BINANCE_SPOT",
    pair: "ETH_USDT",
    exchange: "binance",
    type: "spot",
  },
  {
    id: "LTC_USDT_BINANCE_SPOT",
    pair: "LTC_USDT",
    exchange: "binance",
    type: "spot",
  },
  {
    id: "TON_USDT_BINANCE_SPOT",
    pair: "TON_USDT",
    exchange: "binance",
    type: "spot",
  },
]
