<template>
  <div class="scalper">
    <Clusters v-if="store.clusters" class="scalper-item-component" :style="{ maxWidth: widthOfWidget, width: widthOfWidget, minWidth: widthOfWidget }" :widgetRef="widgetRef" :exchangeId="exchangeId" :baseId="baseId" :quoteId="quoteId" timeframe="1m" :lastN="7" :standalone="false" />
    <Ticks v-if="store.ticks" class="scalper-item-component" :style="{ maxWidth: widthOfWidget, width: widthOfWidget, minWidth: widthOfWidget }" :widgetRef="widgetRef" :exchangeId="exchangeId" :baseId="baseId" :quoteId="quoteId" timeframe="1s" :lastN="3" :standalone="false" />
    <OrderBook v-if="store.orderbook" class="scalper-item-component" :style="{ maxWidth: widthOfWidget, width: widthOfWidget, minWidth: widthOfWidget }" :widgetRef="widgetRef" :exchangeId="exchangeId" :baseId="baseId" :quoteId="quoteId" :standalone="false" />
  </div>
</template>

<script lang="ts" setup>
import Clusters from '../Clusters/Clusters.vue';
import OrderBook from '../OrderBook/OrderBook.vue';
import DebugTable from '../DebugTable/DebugTable.vue';
import Ticks from '../Ticks/Ticks.vue';
import { useRoute } from 'vue-router';
import { useMainStore } from '@/stores/main';
import { ref, toRefs, onMounted, watch, computed } from 'vue';

const props = defineProps({
  exchangeId: String,
  baseId: String,
  quoteId: String,
});
const { exchangeId, baseId, quoteId } = toRefs(props);

const route = useRoute()
// console.log(route.query)

const store = useMainStore()
// console.log({main: main})

// const ordersCenter: any = ref(false)
const widgetRef: any = ref(null)

const widthOfWidget = computed(() => {
  const numberOfWidgets = (store.clusters ? 1 : 0) + (store.ticks ? 1 : 0) + (store.orderbook ? 1 : 0)
  return `${100 / numberOfWidgets}%`
})

// const toCenter = () => {
//   // scroll window to 50000px from top of the page
//   // console.log({scalperRef})
//   // get height of scalperRef
//   // const widgetOffsetHeight = scalperRef.value.offsetHeight // scrollHeight
//   // const widgetScrollHeight = scalperRef.value.scrollHeight
//   // console.log({widgetOffsetHeight, widgetScrollHeight})
//   // window.scrollTo(0, 50000)
// }

// onMounted(() => {
//   toCenter()
// })

</script>

<style scoped lang="sass">
// .scalper
//   display: flex
//   flex-direction: row
//   justify-content: space-between
//   height: 100vh
// .scalper-body
//   // height: 100000px
//   display: flex
//   flex-direction: row
//   justify-content: center
//   overflow-y: hidden
// .scalper-item
//   flex: 1 0 33.3%
.scalper
  display: flex
  height: 100%
  overflow-y: auto
  overflow-x: none
// .scalper-item-component
// height: 100%
// overflow-x: auto
// max-width: 33%
// width: 33%
// min-width: 33%
// width: 300px
// .dashboards
//   display: flex
//   height: 100vh
//   width: 100%
// .dashboard
//   width: 100%
// .dashboard-body
//   display: flex
//   // flex-direction: row
//   justify-content: center
//   align-items: center
//   height: 100%
//   width: 100%
// .widget
//   flex: 1 0 50%
//   border: 3px solid black
//   overflow: auto
//   height: 100%
//   display: flex
//   flex-direction: column
// .widget-body
//   height: 100%
//   flex: 1 0 auto
//   display: flex
//   overflow-x: scroll
//   min-height: 100%
</style>
